import React, { useState, useEffect } from 'react';
import './App.css';
import { ARTWORK } from './data/artwork';
import _ from 'lodash';
import CropComponent from './components/CropComponent';

function App() {
  const [artworkData, setArtworkData] = useState([]);
  const [artworkIndex, setArtworkIndex] = useState(0);
  const [title, setTitle] = useState('stranger things');
  const artwork = ARTWORK[title][artworkIndex];

  const [cropWidth, setCropWidth] = useState(400);
  const [cropHeight, setCropHeight] = useState(200);

  // useEffect(() => {
  //   fetchArtwork(60029814)
  //   .then(console)
  //   .catch(console.log)
  // }, [])

  return (
    <div className="App" style={{
      padding: 20
    }}>
      <h2>Focal Point Cropping Demo</h2>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: 400,
        alignItems: 'flex-start'
      }}>
        <h3>Title</h3>
        <select
          style={{
            padding: `6px 10px`,
            marginBottom: 20
          }}
          onChange={(e) => setTitle(e.target.value)}
        >
          {Object.keys(ARTWORK).map(it => (
            <option key={it} value={it}>{it}</option>
          ))}
        </select>
        {/* <div>
          <span style={{ color: 'white'}}>width</span>
          <input
            style={{ width: 50, marginLeft: 15 }}
            value={cropWidth}
            onChange={(e) => setCropWidth(parseInt(e.target.value))}
          />
        </div>
        <div>
          <span style={{ color: 'white'}}>height</span>
          <input
            style={{ width: 50, marginLeft: 10 }}
            value={cropHeight}
            onChange={(e) => setCropHeight(parseInt(e.target.value))}
          />
        </div> */}
        <h3>Artwork</h3>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
          <button
            style={{
              padding: `6px 14px`
            }}
            onClick={() => {
              if (artworkIndex === 0) {
                setArtworkIndex(ARTWORK[title].length -1);
                return;
              }
              setArtworkIndex(artworkIndex - 1)
            }}
          >
            prev
          </button>
          <div style={{
            color: 'white',
            margin: '0 20px 0'
          }}>
            {`${artworkIndex + 1} of ${ARTWORK[title].length}`}
          </div>
          <button
            style={{
              padding: `6px 14px`
            }}
            onClick={() => {
              // setIsMasked(true);
              if (artworkIndex === ARTWORK[title].length -1) {
                setArtworkIndex(0);
                return;
              }
              setArtworkIndex(artworkIndex + 1)
            }}
          >
            next
          </button>
        </div>
      </div>
      <CropComponent
        cropWidth={cropWidth}
        cropHeight={cropHeight}
        artwork={artwork}
      />
    </div>
  );
}

function fetchArtwork(videoId) {
  return fetch(`https://localhost:7002/artwork?videoId=${videoId}`, {
    headers: {
      'cookie': 'mod_auth_openidc_session=ot8CnA7cYllCBmVHoajPkqm1N705GdJyh0uVFboDjLyQ'
    }
  })
  .then(res => {
    console.log(res)
    return res;
  })
  .then(res => res.json())
}

export default App;
