import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import _ from 'lodash';

function buildUseSpringConfig(isMasked, cropWidth, cropHeight, artwork) {
  const { x: fx, y: fy } = JSON.parse(_.get(artwork, 'attributes.FOCAL_POINT', {}));

  return {
    from: {
      top: isMasked ? 0 : ((artwork.height * fy) - (cropHeight / 2)),
      left: isMasked ? 0 : ((artwork.width * fx) - (cropWidth / 2)),
      opacity: isMasked ? 0 : 1,
      borderOpacity: isMasked ? 0 : 1,
    },
    to: async (next, cancel) => {
      if (!isMasked) {
        await next({
          top: isMasked ? 0 : ((artwork.height * fy) - (cropHeight / 2)),
          left: isMasked ? 0 : ((artwork.width * fx) - (cropWidth / 2)),

        })
        await next({
          opacity: isMasked ? 0 : 1,
        })
      } else {
        await next({
          opacity: isMasked ? 0 : 1,
        })
        await next({
          top: isMasked ? 0 : ((artwork.height * fy) - (cropHeight / 2)),
          left: isMasked ? 0 : ((artwork.width * fx) - (cropWidth / 2)),
        });
      }
    },
    // duration: 100,
  };
}

function CropComponent({ cropWidth, cropHeight, artwork }) {
  const [isMasked, setIsMasked] = useState(true)
  const props = useSpring(buildUseSpringConfig(isMasked, cropWidth, cropHeight, artwork));
  const { x: fx, y: fy } = JSON.parse(_.get(artwork, 'attributes.FOCAL_POINT', {}));

  return (
    <div>
      <h3 style={{ color: 'white', textAlign: 'left' }}>Crop Controls</h3>
      <div style={{ display: 'flex', flex: 1, marginTop: 20}}>
        <button
          style={{
            padding: `6px 14px`
          }}
          onClick={() => setIsMasked(!isMasked)}
        >
          crop/uncrop
        </button>
      </div>
      <div key={artwork.key} style={{ position: 'relative', marginTop: 100 }}>
        <animated.div
          style={{
            position: 'absolute',
            width: cropWidth,
            height: cropHeight,
            overflow: 'hidden',
            border: props.opacity.interpolate(o => `1px dashed rgba(255, 255, 255, ${o})`),
            // animated
            top: props.top.interpolate(t => t),
            left: props.left.interpolate(l => l),
            zIndex: 1
          }}
        >
          <animated.img
            src={artwork.url}
            style={{
              position: 'absolute',
              top: -((artwork.height * fy) - (cropHeight / 2)),
              left: -((artwork.width * fx) - (cropWidth / 2)),
              height: artwork.height,
              width: artwork.width,
              opacity: props.opacity.interpolate(o => 1 - o)
            }}
          />
        </animated.div>
        <animated.img
          src={artwork.url}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: artwork.height,
            width: artwork.width,
            opacity: props.opacity.interpolate(o => o),
          }}
        />
      </div>
    </div>
  );
}
export default CropComponent;